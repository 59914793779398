import React from "react";
import { PropTypes } from "prop-types";
import { MrbButton } from "mrb/components/button";
import { mergeCss } from "mrb/common/utils";
import { deviceMenuTypes as types } from "common/constants";
import { DeviceMenuTypeIcon } from "administration/device-menu/components";
import { isNil } from "lodash";
import { observer } from "mobx-react";

function DeviceMenuItemTemplate({ item, index, provided, isDragging, style, store }) {
    const { deviceMenuSearchViewStore, submitting } = store;

    const isSelected = deviceMenuSearchViewStore.searchResult && deviceMenuSearchViewStore.searchResult.index === index;
    const className = mergeCss(
        "c-device-menu__item",
        isDragging ? "c-device-menu__item--dragged" : null,
        isSelected ? "c-device-menu__item--selected" : null,
        !item.isPremium ? "fullHeight" : null
    );

    return (
        <div style={style}>
            <div
                className={className}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                data-is-dragging={isDragging}
                data-testid={item.id}
                data-index={index}
                index={index}
            >
                <React.Fragment>
                    <div
                        className={`c-device-menu__item__${
                            item.deviceMenuType.abrv === types.separator ? "separator" : "info"
                        }`}
                    >
                        <i className="u-icon u-icon--sml u-icon--drag u-mar--right--tny"></i>
                        <DeviceMenuTypeIcon type={item.deviceMenuType} />

                        <span className="u-type--wgt--bold u-mar--right--tny">{index + 1}.</span>

                        {item.deviceMenuType.abrv === types.separator ? (
                            <div className="u-separator--secondary u-width--100 u-mar--right--tny"></div>
                        ) : (
                            <React.Fragment>{renderLabel(item)}</React.Fragment>
                        )}
                    </div>

                    <div className="u-display--flex">
                        {(item.deviceMenuType.abrv === types.separator ||
                            item.deviceMenuType.abrv === types.search) && (
                            <MrbButton
                                label="Delete"
                                classNameOverride="c-btn c-btn--sml c-btn--ghost u-mar--right--tny"
                                onClick={() => store.removeMenuItem(item.id)}
                                disabled={submitting}
                            />
                        )}
                        {index > 0 && item.deviceMenuType.abrv !== types.separator && (
                            <MrbButton
                                label="Bring to the top"
                                classNameOverride="c-btn c-btn--sml c-btn--primary"
                                onClick={() => store.moveToTop(index)}
                                disabled={submitting}
                            />
                        )}
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
}

DeviceMenuItemTemplate.propTypes = {
    item: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

function renderLabel(item) {
    switch (item.deviceMenuType.abrv) {
        case types.search:
            return <div>Search</div>;
        case types.nakiNigunArtist:
            return <div>Naki Nigun - Artists</div>;
        case types.nakiNigunAlbum:
            return <div>Naki Nigun - Albums</div>;
        case types.nakiTalesCollection:
            return <div>Naki Tales - Collections</div>;
        case types.nakiTalesTitle:
            return <div>Naki Tales - Titles</div>;
        case types.allRecentlyUpdated:
            return <div>All Recently Updated</div>;
        case types.myRecentlyUpdated:
            return <div>Recently Updated</div>;
        case types.playlists:
            return <div>Naki Nigun Shuffles</div>;
        default:
            if (!isNil(item.podcastId) && item.isPremium) {
                return (
                    <div>
                        <div className="c-device-menu__item__title c-device-menu__item__title--secondary">
                            {item.title ?? item.itemTitle}
                            <i className="u-icon u-icon--med u-icon--premium u-mar--left--tny u-mar--right--tny"></i>
                        </div>
                        <span className="u-type--sml u-type--color--primary">Included in Naki Tales</span>
                    </div>
                );
            }
            return <div className="c-device-menu__item__title">{item.title ?? item.itemTitle}</div>;
    }
}

export default observer(DeviceMenuItemTemplate);
